import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import {
	LazyLoadImage,
	LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const NarmEventsPage = ({ data }) => (
	<Layout>
		<SEO
			title="NARM Events"
			description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
		/>
		<div className="page-headline">
			<div className="container">
				<div className="section-heading text-center">
					<h1>
						NARM Events
					</h1>
				</div>
			</div>
		</div>
		<div className="page-content">
		<section id="trainingspage" className="component py-0">
			<div className="container px-5 px-md-3">
				<p>Explore our FREE community events here! You can register for the replay video of any past FREE community event</p>
				<div className="row mt-5">
					{data.allSanityEvents.edges.map(({ node }) => (
						 <div key={node.id} className="col-md-6 col-lg-4 mb-4">
              <div className="card shadow h-100">
                <div
                  className="card-img"
                  style={{
                    backgroundImage: `url(${node.banner !== null ? node.banner.asset.fluid.src : "https://placeimg.com/600/400/nature"})`,
                  }}
                >
                  <div className="card-link">
                    <Link to={node.slug !== null ? "/events/" + node.slug.current : ""}>
                      <LazyLoadImage
                        effect="blur"
                        src="/assets/img/play.svg"
                        width={14}
                        height={14}
                      />
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                	<h6 className="text-uppercase card-subtitle mb-2 font-weight-bold text-orange">
                    {node.subTitle}
                  </h6>
                  <h4 className="card-title text-primary">
                    {node.title}
                  </h4>
                  <div className="card-created">
                    <p className="text-muted mb-0">
                      <LazyLoadImage
                        effect="blur"
                        src="/assets/img/calendar.svg"
                        width={20}
                        height={20}
                      />
                      <span className="ml-2">{node.publishedDate}</span>
                    </p>
                  </div>
                </div>
                <Link className="hidden-link" to={node.slug !== null ? "/events/" + node.slug.current : ""}>
                  Read More
                </Link>
              </div>
            </div>
					))}
				</div>
				
			</div>
			
		</section>
		</div>
	</Layout>
)

export default NarmEventsPage

export const narmEventsQuery = graphql`
	query narmEventsQuery {
    allSanityEvents(
    	sort: { order: DESC, fields: publishedDate },
    ) {
	    edges {
	      node {
	        id
	        title
	        subTitle
	        shortDescription
	        publishedDate(locale: "US", formatString: "MMM DD, YYYY")
	        slug {
	        	current
	        }
	        banner {
	          alt
	          asset {
	            fluid {
	              src
	            }
	          }
	        }
	      }
	    }
	  }
	}
`
